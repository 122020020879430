import React from "react";
import {Menu} from 'antd';
import {Link} from "react-router-dom";

const {SubMenu} = Menu;

const recursion = (dataSource) => {
    if(!dataSource){
        return
    }
    return (
        dataSource.map((menu) => {
            menu.key = menu.key || menu.title;
            if (menu.children) {
                return (
                    <SubMenu key={menu.key} title={<span>
                        {menu.title}</span>}>
                        {recursion(menu.children)}
                    </SubMenu>
                )
            } else {
                return (<Menu.Item key={menu.key}>
                    <Link to={menu.url}>
                        {menu.title}
                    </Link>
                </Menu.Item>)
            }
        })
    )
};

export default ({data, ...others}) => {
    return <Menu{...others}>{recursion(data)}</Menu>
};
/*
const menuData= [
    {
        "key": "course",
        // "icon": "user",
        "title": "在线课堂",
        "children": [
            {
                "key": "courses",
                "icon": "file",
                "title": "我的课程",
                "url": "/courses"
            },
            {
                "key": "report",
                "title": "实验报告",
                "url": "/report"
            },
        ]
    },]
*/