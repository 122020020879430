const menuData = [
    {
        "title": "在线课堂",
        "children": [
            {
                "title": "我的课程",
                "url": "/t/courses"
            },
            {
                "title": "学生作业",
                "url": "/t/report/boxes"
            },
            {
                "title": "常用评语",
                "url": "/t/report/comment"
            },
            {
                "title": "软件下载",
                "url": "/s/download"
            }
            // {
            //     "title": "学生",
            //     "url": "/a/students"
            // },
        ]
    },
    {
        "title": "资源管理",
        "url": "/s/resource/manage"
    },
    // {
    //     "title": "日常管理",
    //     "children": [
    //         {
    //             "title": "用户",
    //             "url": "/a/students"
    //         },
    //         {
    //             "title": "用户组",
    //             "url": "/t/user_groups"
    //         },
    //     ]
    // },
    {
         "title": "教学管理",
         "children": [
             {
                 "title": "名单",
                 "url": "/t/user_lists"
             }
         ]
    },
    // {
    //     "title": "在线实验",
    //     "url": "/t/devices"
    // },
    {
        "title": "我的仪器",
        "children": [
            // {
            //     "title": "硬木课堂",
            //     "url": "/s/emooc"
            // },
            // {
            //     "title": "鼎阳",
            //     "url": "/s/siglent"
            // },
            // {
            //     "title": "普源精电",
            //     "url": "/s/rigol"
            // },
            {
                "title": "我的实验箱",
                "url": "/t/experimentBox"
            }
        ]
    },
    // {
    //     "title": "在线演示实验",
    //     "url": "/t/online"
    // }

];


module.exports = menuData;
