import React from "react";
import {HashRouter as Router, Route, Switch, withRouter} from "react-router-dom";
import loadable from "@loadable/component";
import LoginService from "./services/Login";
import BasicLayout from "./layout/BasicLayout";
import IframeLayout from "./layout/IframeLayout";

import { ConfigProvider } from 'antd';
// 由于 antd 组件的默认文案是英文，所以需要修改为中文
import zhCN from 'antd/lib/locale/zh_CN'
const NotMatch = () => <div>NotMatch</div>;
const Loading = () => <div>加载中...</div>;

const ChangeTracker = withRouter(({match, location, history}) => {
    console.log(location.pathname, location.state);
    if (location.pathname !== '/login'&&!location.pathname.startsWith('/report/')&&location.pathname !== '/register'&&location.pathname !== '/reset'&&location.pathname !== '/a/input') {
        if (LoginService.isExpired()) {
            console.log("登录时间过期！");
            window.location.hash = '/login';
        }
    }
    return false;
});


const Login = loadable(() => import("./routes/Login"), {fallback: <Loading/>});
const RegisterPage = loadable(() => import("./routes/Register"), {fallback: <Loading/>});
const ResetPage = loadable(() => import("./routes/Reset"), {fallback: <Loading/>});
const ChangeUserInfo = loadable(() => import("./routes/ChangeUserInfo"), {fallback: <Loading/>});


const TeacherCourses = loadable(() => import("./routes/teacher/Courses"), {fallback: <Loading/>});
const TeacherCourse = loadable(() => import("./routes/teacher/Course"), {fallback: <Loading/>});
const TeacherCourseSetting = loadable(() => import("./routes/teacher/CourseSetting"), {fallback: <Loading/>});
const TeacherCourseClass = loadable(() => import("./routes/teacher/CourseClass"), {fallback: <Loading/>});
const TeacherViewStudentInfo = loadable(() => import("./routes/teacher/ViewStudentInfo"), {fallback: <Loading/>});
const TeacherCourseClassEdit = loadable(() => import("./routes/teacher/CourseClassEdit"), {fallback: <Loading/>});
const ImportUser = loadable(() => import("./routes/admin/ImportUser"), {fallback: <Loading/>});
const ImportUserGroup = loadable(() => import("./routes/admin/ImportUserGroup"), {fallback: <Loading/>});
const ImportStudent = loadable(() => import("./routes/admin/ImportStudent"), {fallback: <Loading/>});
const ImportTeacher = loadable(() => import("./routes/admin/ImportTeacher"), {fallback: <Loading/>});
const ImportAdmin = loadable(() => import("./routes/admin/ImportAdmin"), {fallback: <Loading/>});
const ResourceManage = loadable(() => import("./routes/student/ResourceManage"), {fallback: <Loading/>});
const Users = loadable(() => import("./routes/admin/Users"), {fallback: <Loading/>});
const UserGroups = loadable(() => import("./routes/admin/UserGroups"), {fallback: <Loading/>});
const UserGroup = loadable(() => import("./routes/admin/UserGroup"), {fallback: <Loading/>});
const UserGroupEdit = loadable(() => import("./routes/admin/UserGroupEdit"), {fallback: <Loading/>});
const UserEdit = loadable(() => import("./routes/admin/UserEdit"), {fallback: <Loading/>});
const Students = loadable(() => import("./routes/admin/Students"), {fallback: <Loading/>});
const Teachers = loadable(() => import("./routes/admin/Teachers"), {fallback: <Loading/>});
const Admin = loadable(() => import("./routes/admin/Admin"), {fallback: <Loading/>});
const AdminCourses = loadable(() => import("./routes/admin/Courses"), {fallback: <Loading/>});
const AdminWordAddin = loadable(() => import("./routes/admin/WordAddin"), {fallback: <Loading/>});
const AdminReportBoxes = loadable(() => import("./routes/admin/reportBoxes"), {fallback: <Loading/>});
const AdminReportBoxesView = loadable(() => import("./routes/admin/reportBoxesView"), {fallback: <Loading/>});
const AdminReportCommentView = loadable(() => import("./routes/admin/ReportCommentView"), {fallback: <Loading/>});
const AdminBindList = loadable(() => import("./routes/admin/BindList"), {fallback: <Loading/>});
const AdminInput = loadable(() => import("./routes/admin/InputText"), {fallback: <Loading/>});
const AdminCourseCopy = loadable(() => import("./routes/admin/CourseCopy"), {fallback: <Loading/>});
const DataStatistics = loadable(() => import("./routes/admin/DataStatistics"), {fallback: <Loading/>});
const OnlineDevices = loadable(() => import("./routes/admin/OnlineDevices"), {fallback: <Loading/>});
const UpdateInfo = loadable(() => import("./routes/admin/UpdateInfo"), {fallback: <Loading/>});



// 教师实验箱管理
const ExperimentBox = loadable(() => import("./routes/admin/ExperimentBox"), {fallback: <Loading/>})
// 批量导入教师实验箱
const ImportTeacherBox = loadable(() => import("./routes/admin/ImportTeacherBox"),{fallback:<Loading />})
const TeacherReportComment = loadable(() => import("./routes/teacher/ReportComment"), {fallback: <Loading/>});
const TeacherReportBoxes = loadable(() => import( "./routes/teacher/ReportBoxes"), {fallback: <Loading/>});
const TeacherReportBox = loadable(() => import( "./routes/teacher/ReportBox"), {fallback: <Loading/>});
const TeacherReportBoxSetting = loadable(() => import( "./routes/teacher/ReportBoxSetting"), {fallback: <Loading/>});
const TeacherDevices = loadable(() => import( "./routes/teacher/Devices"), {fallback: <Loading/>});
const TeacherDeviceSetting = loadable(() => import( "./routes/teacher/DeviceSetting"), {fallback: <Loading/>});
const TeacherExps = loadable(() => import( "./routes/teacher/Exps"), {fallback: <Loading/>});
const ReportComments = loadable(() => import( "./routes/teacher/Comments"), {fallback: <Loading/>});
const TeacherUserGroups = loadable(() => import("./routes/teacher/UserGroups"), {fallback: <Loading/>});
const TeacherImportComments = loadable(() => import("./routes/teacher/ImportComments"), {fallback: <Loading/>});
// 我的实验箱
const TeacherBox = loadable(() => import("./routes/teacher/TeacherBox"),{fallback: <Loading />})
// 名单
const UserLists = loadable(() => import("./routes/teacher/UserLists"),{fallback: <Loading/>})
// 名单之一
const UserListName = loadable(() => import("./routes/teacher/UserListName"),{fallback: <Loading/>})
// 在线实验演示
const Online = loadable(() => import("./routes/teacher/Online"),{fallback: <Loading/>})

const StudentReportBoxes = loadable(() => import( "./routes/student/ReportBoxes"), {fallback: <Loading/>});
const StudentReportBox = loadable(() => import( "./routes/student/ReportBox"), {fallback: <Loading/>});
const StudentReportCommentView = loadable(() => import("./routes/student/ReportCommentView"), {fallback: <Loading/>});
const StudentCourses = loadable(() => import( "./routes/student/Courses"), {fallback: <Loading/>});
const StudentCourse = loadable(() => import( "./routes/student/Course"), {fallback: <Loading/>});
const StudentExpPage = loadable(() => import( "./routes/student/ExpPage"), {fallback: <Loading/>});
const StudentDownloadPage = loadable(() => import( "./routes/student/DownloadPage"), {fallback: <Loading/>});
const StudentWordAddinPage = loadable(() => import( "./routes/student/WordAddinPage"), {fallback: <Loading/>});
const ReportView = loadable(() => import( "./routes/student/ReportView"), {fallback: <Loading/>});
const StudentBindDevice = loadable(() => import( "./routes/student/BindDevice"), {fallback: <Loading/>});
const StudentEmoocPage = loadable(() => import( "./routes/student/EmoocPage"), {fallback: <Loading/>});
const StudentSiglentPage = loadable(() => import( "./routes/student/SiglentPage"), {fallback: <Loading/>});
const StudentRigolPage = loadable(() => import( "./routes/student/RigolPage"), {fallback: <Loading/>});

const Home = loadable(() => import( "./routes/Home"), {fallback: <Loading/>});
//const Home = () => <div>Home</div>;

const StudentExpPageWithLayout=(props)=>(
    <IframeLayout><StudentExpPage {...props}/></IframeLayout>);
const TeacherReportCommentWithLayout=(props)=>(
    <IframeLayout><TeacherReportComment {...props}/></IframeLayout>);

export default () => {
    return (
        <ConfigProvider locale={zhCN}>
        <Router>
            <ChangeTracker/>
            <Switch>
                <Route path="/report/:id" component={ReportView}/>
                <Route path="/a/input" component={AdminInput}/>

                <Route exact path="/login" component={Login}/>
                <Route exact path="/register" component={RegisterPage}/>
                <Route exact path="/reset" component={ResetPage}/>
                <Route exact path="/s/exp_page/:courseId/:id" component={StudentExpPageWithLayout}/>
                <Route path="/t/report/comment/:reportId/:boxId/:orderField/:orderMethod" component={TeacherReportCommentWithLayout}/>
                <BasicLayout>
                    <Switch>
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/change_info" component={ChangeUserInfo}/>
                        {/*教师*/}
                        <Route exact path="/t/courses" component={TeacherCourses}/>
                        <Route path="/t/course/:id" component={TeacherCourse}/>
                        <Route path="/t/course_setting/:id" component={TeacherCourseSetting}/>
                        <Route path="/t/course_class/:id" component={TeacherCourseClass}/>
                        <Route path="/t/course_class_view_student/:username/:id" component={TeacherViewStudentInfo}/>
                        <Route path="/t/course_class_edit/:id" component={TeacherCourseClassEdit}/>
                        <Route path="/t/report/boxes" component={TeacherReportBoxes}/>
                        <Route path="/t/report/box/:id" component={TeacherReportBox}/>
                        <Route path="/t/report/box_setting/:id" component={TeacherReportBoxSetting}/>
                        <Route path="/t/devices" component={TeacherDevices}/>
                        <Route path="/t/device_setting/:id" component={TeacherDeviceSetting}/>
                        <Route path="/t/exps/:id" component={TeacherExps}/>
                        <Route path="/t/report/comment" component={ReportComments}/>
                        <Route path="/t/user_groups" component={TeacherUserGroups}/>
                        <Route path="/t/i_comments" component={TeacherImportComments}/>
                        <Route path="/t/experimentBox" component={TeacherBox} />
                        <Route path="/t/user_lists" component={UserLists}/>
                        <Route path="/t/user_list/:name" component={UserListName} />
                        <Route path="/t/online" component={Online} />

                        {/*管理员*/}
                        <Route path="/a/i_user" component={ImportUser}/>
                        <Route path="/a/i_student" component={ImportStudent}/>
                        <Route path="/a/i_teacher" component={ImportTeacher}/>
                        <Route path="/a/i_admin" component={ImportAdmin}/>
                        <Route path="/a/i_user_group" component={ImportUserGroup}/>
                        <Route path="/a/users" component={Users}/>
                        <Route path="/a/user_groups" component={UserGroups}/>
                        <Route path="/a/user_group/:school/:name" component={UserGroup}/>
                        <Route path="/t/user_group/:school/:name" component={UserGroup}/>
                        <Route path="/a/user_group_setting/:id" component={UserGroupEdit}/>
                        <Route path="/a/students" component={Students}/>
                        <Route path="/a/teachers" component={Teachers}/>
                        <Route path="/a/admin" component={Admin}/>
                        <Route path="/a/userEdit/:username" component={UserEdit}/>
                        <Route path="/a/courses" component={AdminCourses}/>
                        <Route path="/a/word_addin" component={AdminWordAddin}/>
                        <Route path="/a/report_boxes" component={AdminReportBoxes}/>
                        <Route path="/a/report_boxes_view/:id" component={AdminReportBoxesView}/>
                        <Route path="/a/report_commentview/:reportId/:boxId" component={AdminReportCommentView}/>
                        <Route path="/a/bind_list" component={AdminBindList}/>
                        <Route path="/a/course_copy" component={AdminCourseCopy}/>
                        <Route path="/a/data_statistics" component={DataStatistics}/>
                        <Route path="/a/online_devices" component={OnlineDevices}/>
                        <Route path="/a/update" component={UpdateInfo}/>
                        <Route exact path="/a/i_teacher_box" component={ImportTeacherBox} />

                        {/*教师实验箱管理*/}
                        <Route path="/a/experiment_box" component={ExperimentBox}/>

                        {/*学生*/}
                        <Route path="/s/report/box/:boxId" component={StudentReportBox}/>
                        <Route path="/s/report/boxes" component={StudentReportBoxes}/>
                        <Route path="/s/report/commentview/:reportId/:boxId" component={StudentReportCommentView}/>
                        <Route path="/s/courses" component={StudentCourses}/>
                        <Route path="/s/course/:id" component={StudentCourse}/>
                        <Route path="/s/resource/manage" component={ResourceManage}/>


                        <Route path="/s/exp_page/:courseId/:id" component={StudentExpPage}/>
                        <Route path="/s/download" component={StudentDownloadPage}/>
                        <Route path="/s/wordaddin" component={StudentWordAddinPage}/>
                        <Route path="/s/bind_device" component={StudentBindDevice}/>
                        <Route path="/s/emooc" component={StudentEmoocPage}/>
                        <Route path="/s/siglent" component={StudentSiglentPage}/>
                        <Route path="/s/rigol" component={StudentRigolPage}/>

                        <Route path="/*" component={NotMatch}/>
                    </Switch>
                </BasicLayout>
                {/*<IframeLayout>*/}
                    {/*<Switch>*/}
                        {/*<Route path="/s/exp_page/:courseId/:id" component={StudentExpPage}/>*/}
                    {/*</Switch>*/}
                {/*</IframeLayout>*/}
            </Switch>
        </Router>
        </ConfigProvider>
    );
}

