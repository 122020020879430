const menuData = [
            {
                "title": "课程列表",
                "url": "/a/courses",
            },
            {
                "title": "作业箱",
                "url": "/a/report_boxes",
            },
    ];

module.exports = menuData;
